import React from 'react';
import styles from './startConnecting.module.scss';
import Section from '@/components/Section';
import { Button } from '@/components/Buttons';
import { getRefLink } from '@/lib/helpers';
import { useTranslation } from 'next-i18next';

const StartConnectingSection = () => {
    const { t } = useTranslation();
    return (
        <Section>
            <div className={styles.startConnectingSection}>
                <div className={styles.startConnectingTextWrapper}>
                    <p className={'as-h2'}>{t('start_connecting_section.title' as any)}</p>
                    <p>{t('start_connecting_section.subtitle' as any)}</p>
                    <Button to={getRefLink()} color={'primary'} size={'large'} blank={false}>
                        {t('register_button_label' as any)}
                    </Button>
                </div>
            </div>
        </Section>
    );
};

export default React.memo(StartConnectingSection);

StartConnectingSection.displayName = 'StartConnectingSection';
