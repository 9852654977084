import React, { CSSProperties } from 'react';
import styles from './icon-list.module.scss';

interface IconListItemProps {
    icon: React.ReactNode | JSX.Element;
    title: string;
    description?: string;
    withoutIconBackground?: boolean;
    titleStyle?: CSSProperties;
    checkIcon?: boolean;
    textWrapperStyles?: CSSProperties;
    wrapperStyles?: CSSProperties;
}

const IconListItem: React.FC<IconListItemProps> = ({
    withoutIconBackground,
    icon,
    title,
    description,
    titleStyle,
    textWrapperStyles,
    wrapperStyles,
    checkIcon = false,
}) => {
    return (
        <div
            className={`${styles.wrapper} ${checkIcon ? styles.checkIconWrapper : ''}`}
            style={wrapperStyles}
        >
            <div
                className={`${styles.iconWrapper} ${
                    withoutIconBackground ? styles.iconWrapper_withoutBg : ''
                }`}
            >
                {icon}
            </div>
            <div className={styles.textWrapper} style={textWrapperStyles}>
                <p className={`as-h4 ${styles.text}`} style={titleStyle}>
                    {title}
                </p>
                {description && <p className={'small'}>{description}</p>}
            </div>
        </div>
    );
};

export default IconListItem;
